import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Sharps Kit">
    <Hero title="Using the Sharps Kit" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard>
          <EmberCardSection noSeparator>
            <Stack>
              <Text>
                For the rare occasion a driver may find a needle or other sharp
                object on board we provide sharps kits to allow for the sharp to
                be safely discarded.
              </Text>
              <Text>
                These are kept in the small locker underneath the drivers
                window. In the same locker as the cleaning kit. Each kit
                contains gloves, tweezers, sanitiser wipes and safe disposal
                boxes.
              </Text>
              <StaticImage
                src="./images/sharps-kit.jpg"
                alt="Sharps Kit"
                width={500}
              />
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard>
          <EmberCardSection title="Using the Sharps Kit">
            <Stack>
              <Text>Put on the gloves from the kit</Text>
              <Text>
                Place the yellow disposal bag close to the sharp object, being
                careful to avoid touching it.
              </Text>
              <Text>
                Use the tweezers to lift the sharp item into the bag, keeping
                your hand behind the sharp end at all times.
              </Text>
              <StaticImage
                src="./images/pick-up-item.jpg"
                alt="Pick Up Sharp Item"
                width={500}
              />
              <Text>
                Once the sharp item is in the bag, seal the bag whilst keeping
                the sharp end away from you. Place the bag sharp end first into
                the yellow plastic tub in the kit.
              </Text>
              <StaticImage
                src="./images/placing-item-in-bag.jpg"
                alt="Placing Sharp Item in the Bag"
                width={500}
              />
              <StaticImage
                src="./images/yellow-bin.jpg"
                alt="Yellow Sharps Bin"
                width={500}
              />
              <Text>
                Use the antiseptic wipes to clean the area where the sharp was
                lay. Return the yellow bin to the kit and dispose of wipe and
                gloves in the nearest bin. Notify the Operations Team in your
                Google Chat room to say that the kit has been used.
              </Text>
              <Text>
                You should never try to touch the pointy end of the sharp or put
                your fingers into the top of the sharps bin at any time.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
